import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    user_info:{
      favourites: [],
      favouritesMoto: []
    },
    user: false
  },
  getters: {
  },
  mutations: {
    authUser(state, elem){
      state.user = true;
      state.user_info = elem;
    },
    exitUser(state){
      state.user = false;
      state.user_info = {};
    },
    updateUser(state, elem){
      state.user_info.name = elem.name;
      state.user_info.phone = elem.phone;
      state.user_info.mail = elem.mail;
    },
    updatePassword(state, elem){
      state.user_info.password = elem
    },
    authFavourites(state, elem){
      state.user_info.favourites = elem
    },
    authFavouritesMoto(state, elem){
      state.user_info.favouritesMoto = elem
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState(
      {
        paths: ['user', 'user_info']
      }
    )
  ]
})
