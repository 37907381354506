<template>
  <div class="modal fade px-0" :id="'openBidImg'+ind" tabindex="-1" aria-labelledby="openBidImgLabel" aria-hidden="true" v-for="(itemInfo, ind) in itemsNews" :key="ind">
    <div class="modal-dialog modal-fullscreen modal-dialog-centered" style="height: fit-content;">
      <div class="modal-content" style="background: none;">
        <div :id="'itemSliderModalBid'+ind" class="carousel slide item-slider" data-bs-touch="true" data-bs-interval="false">

          <div class="carousel-inner">
            <div class="carousel-item" :class="{'active': index === openImgSrc}" v-for="(img, index) in JSON.parse(itemInfo.images)" :key="index">
              <img v-lazy="'https://samui31.com/api/images/'+img" class="d-block w-100" style="object-fit: contain; height: 100vh; max-width: 100vw; cursor: pointer;" data-bs-dismiss="modal">
            </div>
            <div class="carousel-item item-preview" v-if="itemInfo['video']">
                <div class="mb-3" style="object-fit: contain; height: 90vh; width: 90vw; margin: 5vh 5vw; cursor: pointer;" v-html="itemInfo['video']"></div>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" :data-bs-target="'#itemSliderModalBid'+ind"  data-bs-slide="prev" >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Предыдущий</span>
          </button>
          <button class="carousel-control-next" type="button" :data-bs-target="'#itemSliderModalBid'+ind" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Следующий</span>
          </button>
          </div>

      </div>
    </div>
  </div>

    <div class="catalog-page">
        <div class="container">
            <h1 class="catalog-page-title pt-4 text-center" style="font-size: 28px; font-weight: 700;">Блог</h1>

            <div class="catalog catalog-main" style="min-height: 300px;">
            <div class="row">
                <div class="col-12 mb-5" v-for="(item, index) in itemsNews" :key="item.id">
                <div class="row">
                    <div class="col-lg-3">
                    <div :id="'carouselExampleIndicatorsNews'+item.id" class="carousel slide" data-bs-touch="true" style="position: relative;">
                        <div class="carousel-indicators">
                            <button type="button" v-for="(img, index) in JSON.parse(item.images)" :key = "index" :class='{active: index == 0}' :data-bs-target="'#carouselExampleIndicatorsNews'+item.id" :data-bs-slide-to="index" aria-current="true"></button>
                            <button type="button" v-if="item['video']" :data-bs-target="'#carouselExampleIndicatorsNews'+item.id" :data-bs-slide-to="JSON.parse(item.images).length" aria-current="true"></button>
                        </div>

                        <div class="carousel-inner">
                        <div class="carousel-item item-preview" v-for="(img, ind) in JSON.parse(item.images)" :key = "ind" :class='{active: ind == 0}'>
                            <img v-lazy="'https://samui31.com/api/images_mini/'+img" class="d-block w-100" @click="openImgGetSrc(ind, index)" data-bs-toggle="modal" :data-bs-target="'#openBidImg'+index" style="cursor: pointer; aspect-ratio: 1 / 1; -o-object-fit: cover; object-fit: cover; border-radius: 15px;" alt="...">
                        </div>
                        <div class="carousel-item item-preview" v-if="item['video']">
                            <div class="mb-3" style="cursor: pointer; aspect-ratio: 1 / 1; -o-object-fit: cover; object-fit: cover; border-radius: 15px;" v-html="item['video']"></div>
                        </div>
                        
                        </div>
                        <button class="carousel-control-prev" type="button" :data-bs-target="'#carouselExampleIndicatorsNews'+item.id" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" :data-bs-target="'#carouselExampleIndicatorsNews'+item.id" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                        </button>

                    </div>
                    </div>
                    <div class="col-lg-9 mt-lg-0 mt-3">
                    <div class="d-flex justify-content-between mb-2">
                        <b class="d-block my-auto" style="font-size: 1.2em;">{{ item.title }}</b>
                        <span style="opacity: 0.8; font-size: 0.8em;" class="d-block my-auto">{{ item.date.slice(0, 10) }}</span>
                    </div>
                    <p style="white-space: pre-wrap; max-height: 9lh; font-size: 0.9em; overflow-y: hidden;" v-if="!openNews[index]">{{ item.description }}</p>
                    <a @click="openNews[index] = true" class="d-block ms-auto" style="opacity: 0.8; font-size: 0.9em; float: right;" v-if="!openNews[index]">Читать полностью</a>
                    <p style="white-space: pre-wrap; font-size: 0.9em;" v-if="openNews[index]">{{ item.description }}</p>
                    </div>
                </div>
                    
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import axios from 'axios'
export default{
    data(){
        return{
            itemsNews: [],
            openNews: [],
            openImgSrc: 0
        }
    },
    methods: {
        loadData(){
            axios.get('https://samui31.com/api/get_items_news.php').then((response)=>{
                console.log(response.data)
               this.itemsNews = response.data

               for(let i=0; i<this.itemsNews.length; i++){
                this.openNews[i] = false
               }
            })
        },

        openImgGetSrc(index, slider){
            document.querySelector('#itemSliderModalBid'+slider).querySelector('.active').classList.remove('active')
            document.querySelector('#itemSliderModalBid'+slider).querySelectorAll('.carousel-item')[index].classList.add('active')
            this.openImgSrc = index
            console.log(this.openImgSrc)
        }
    },
    created(){
        this.loadData()
    }
}
</script>