<template>
    <div class="modal fade" id="offerModal" tabindex="-1" aria-labelledby="offerModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="offerModalLabel">Оставить заявку</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"></button>
        </div>
        <div class="modal-body d-flex">
          <form class="w-75 mx-auto my-2">
            <input type="text" class="form-control mt-3" v-model="offer.name" placeholder="Введите ФИО" required>
            <input type="text" class="form-control mt-3" v-model="offer.phone" placeholder="Введите номер телефона" required>
            <input type="text" class="form-control mt-3" v-model="offer.mail" placeholder="Введите E-mail" required>
          </form>
        </div>
        <div class="modal-footer">
            <button class="btn-white" @click="sendOffer">Отправить</button>
        </div>
      </div>
    </div>
  </div>

    <div class="catalog-page">
        <div class="container">

            <h1 class="catalog-page-title pt-4 text-center" style="font-size: 28px; font-weight: 700;">{{ $props.type }} недвижимости</h1>

            <div class="row mt-4">
                <!--Мобильный фильтр-->
                <div class="col-lg-3 col-12 d-lg-none d-block">
                    <div class="accordion" id="filterOpen">
                        <div class="accordion-item">
                            <h2 class="accordion-header ">
                            <button class="accordion-button" :class="{'collapsed': windowWidth <= 992}" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" :aria-expanded="windowWidth>992" aria-controls="collapseOne">
                               <span class="w-50 ms-4 text-end" style="font-weight: 600;">Фильтр</span>
                            </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse" :class="{'show': windowWidth > 992}" data-bs-parent="#filterOpen">
                            <div class="accordion-body p-0">
                                
                            
                    <div class="catalog-filter-block d-lg-flex flex-column pb-0">
                        <label class="pb-2">Стоимость</label>
                        <div class="d-flex mb-4">
                            <input type="text" class="form-control text-center" style="font-size: 0.9em; height: 25px;" v-model="priceSlider.value[0]"/>
                            <div class="my-auto mx-2">-</div>
                            <input type="text" class="form-control text-center"  style="font-size: 0.9em; height: 25px;" v-model="priceSlider.value[1]"/>
                        </div>
                        <Slider style="width: 75%; margin: 0 12.5%; display: none;" v-model="priceSlider.value" v-bind="priceSlider"/>
                        <select class="selectpicker py-0" style="font-size: 0.9em; height: 30px;" v-model="filter.place" multiple>
                            <option selected value=""  style="display: none !important;">Выбрать район</option>
                            <option>Липаной (Lipa Noi)</option>
                            <option>Натон (Nathon)</option>
                            <option>БангПо (Bang Po)</option>
                            <option>БанТай (Ban Tai)</option>
                            <option>Майнам (Maenam)</option>
                            <option>БоПут (Bo Phut)</option>
                            <option>БангРак (BangRak)</option>
                            <option>ПлайЛаем (Plai Laem)</option>
                            <option>ЧонгМон (Cheng Mon)</option>
                            <option>Чавенг (Chaweng)</option>
                            <option>Чавенг Ной (Chaweng Noi)</option>
                            <option>Ламай (Lamai)</option>
                            <option>ХуаТанон (Hua Thanon) </option>
                            <option>Талингнам (Taling Ngam) </option>
                        </select>

                                <label class="text-center w-100 mt-4">Количество спален</label>
                                <div class="d-flex w-100">
                                <div class="btn-group mt-2 mx-auto" role="group" aria-label="Basic radio toggle button group" style="height: fit-content;">
                                    
                                    <input type="checkbox" class="btn-check" name="btnradio[]" id="btnradio2" autocomplete="off" value="1" v-model="filter.bedroom">
                                    <label class="btn btn-white" for="btnradio2" style=" padding: 5px 15px; flex: none;">1</label>

                                    <input type="checkbox" class="btn-check" name="btnradio[]" id="btnradio3" autocomplete="off" value="2" v-model="filter.bedroom">
                                    <label class="btn btn-white" for="btnradio3" style="border-radius: 0; padding: 5px 15px; flex: none;">2</label>

                                    <input type="checkbox" class="btn-check" name="btnradio[]" id="btnradio4" autocomplete="off" value="3" v-model="filter.bedroom">
                                    <label class="btn btn-white" for="btnradio4" style="border-radius: 0; padding: 5px 15px; flex: none;">3</label>

                                    <input type="checkbox" class="btn-check" name="btnradio[]" id="btnradio5" autocomplete="off" value="4" v-model="filter.bedroom">
                                    <label class="btn btn-white" for="btnradio5" style="padding: 5px 15px; flex: none;">4+</label>
                                </div>
                                </div>

                        <label class="mb-2 text-center mt-4 w-100">Техническое оснащение</label>

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" value="pool" id="service_9" v-model="filter.service">
                                        <label class="form-check-label" for="service_9">
                                            Бассейн
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" value="gym" id="service_1" v-model="filter.service">
                                        <label class="form-check-label" for="service_1">
                                            Тренажерный зал
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" value="cond" id="service_15" v-model="filter.service">
                                        <label class="form-check-label" for="service_15">
                                            Кондиционер
                                        </label>
                                    </div>

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" value="tv" id="service_5" v-model="filter.service">
                                        <label class="form-check-label" for="service_5">
                                            Телевизор
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" value="wm" id="service_4" v-model="filter.service">
                                        <label class="form-check-label" for="service_4">
                                            Стирал. машина
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" value="dw" id="service_2" v-model="filter.service">
                                        <label class="form-check-label" for="service_2">
                                            Посудом. машина
                                        </label>
                                    </div>

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" value="micro" id="service_8" v-model="filter.service">
                                        <label class="form-check-label" for="service_8">
                                            Микроволновка
                                        </label>
                                    </div>

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" value="coffee" id="service_12" v-model="filter.service">
                                        <label class="form-check-label" for="service_12">
                                            Кофемашина
                                        </label>
                                    </div>

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" value="plate" id="service_7" v-model="filter.service">
                                        <label class="form-check-label" for="service_7">
                                            Плита
                                        </label>
                                    </div>

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" value="oven" id="service_16" v-model="filter.service">
                                        <label class="form-check-label" for="service_16">
                                            Духовка
                                        </label>
                                    </div>

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" value="hood" id="service_17" v-model="filter.service">
                                        <label class="form-check-label" for="service_17">
                                            Вытяжка
                                        </label>
                                    </div>

                                    <label class="mb-2 mt-4">Расстояние до пляжа:</label>
                                <select class="form-control form-select py-0" style="height: 30px;" v-model="filter.beach">
                                    <option value="">Не важно</option>
                                    <option value="500">До 500 м</option>
                                    <option value="1000">До 1000 м</option>
                                    <option value="2000">До 2000 м</option>
                                </select>

                                <div v-if="$props.type !== 'Покупка'">
                                    <label class="form-check-label mt-3">
                                        Домашние питомцы
                                    </label>

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="1" id="special_1" v-model="filter.animals">
                                        <label class="form-check-label" for="special_1">
                                            Разрешается
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="2" id="special_2" v-model="filter.animals">
                                        <label class="form-check-label" for="special_2">
                                            Обсуждается
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="0" id="special_3" v-model="filter.animals">
                                        <label class="form-check-label" for="special_3">
                                            Запрещается
                                        </label>
                                    </div>
                                </div>

                        <a href="#" class="btn-white d-block mt-4 ms-auto" style="font-size: 0.9em;" @click="applyFilter(0)">Применить</a>
                    </div>
                    
                    </div>
                    </div>
                    </div>
                    </div>

                </div>

                <!--Десктоп фильтр-->

                <div class="col-12 d-lg-block d-none">
                            
                    <div class="catalog-filter-block catalog-filter-block-desktop pb-0">
                        <div class="row">
                            <div class="col-4 py-1 px-5">
                                <label class="pb-2 text-center w-100">Стоимость <span v-if="$props.type=='Аренда'">в месяц</span></label>
                                <div class="d-flex mb-4">
                                    <input type="text" class="form-control text-center" style="font-size: 0.9em; height: 25px;" v-model="priceSlider.value[0]"/>
                                    <div class="my-auto mx-2">-</div>
                                    <input type="text" class="form-control text-center"  style="font-size: 0.9em; height: 25px;" v-model="priceSlider.value[1]"/>
                                </div>
                                <Slider style="width: 75%; margin: 0 12.5%; display: none;" v-model="priceSlider.value" v-bind="priceSlider"/>
                                        <select class="selectpicker py-0" multiple style="font-size: 0.9em; height: 30px;" v-model="filter.place">
                                            <option selected value="" style="display: none !important;">Выбрать район</option>
                                            <option>Липаной (Lipa Noi)</option>
                                            <option>Натон (Nathon)</option>
                                            <option>БангПо (Bang Po)</option>
                                            <option>БанТай (Ban Tai)</option>
                                            <option>Майнам (Maenam)</option>
                                            <option>БоПут (Bo Phut)</option>
                                            <option>БангРак (BangRak)</option>
                                            <option>ПлайЛаем (Plai Laem)</option>
                                            <option>ЧонгМон (Cheng Mon)</option>
                                            <option>Чавенг (Chaweng)</option>
                                            <option>Чавенг Ной (Chaweng Noi)</option>
                                            <option>Ламай (Lamai)</option>
                                            <option>ХуаТанон (Hua Thanon) </option>
                                            <option>Талингнам (Taling Ngam) </option>
                                        </select>
                                        
                                <label class="text-center w-100 mt-2">Количество спален</label>
                                <div class="d-flex w-100">
                                <div class="btn-group mt-2 mx-auto" role="group" aria-label="Basic radio toggle button group" style="height: fit-content;">
                                    
                                    <input type="checkbox" class="btn-check" name="btnradio[]" id="btnradio2" autocomplete="off" value="1" v-model="filter.bedroom">
                                    <label class="btn btn-white" for="btnradio2" style=" padding: 5px 15px; flex: none;">1</label>

                                    <input type="checkbox" class="btn-check" name="btnradio[]" id="btnradio3" autocomplete="off" value="2" v-model="filter.bedroom">
                                    <label class="btn btn-white" for="btnradio3" style="border-radius: 0; padding: 5px 15px; flex: none;">2</label>

                                    <input type="checkbox" class="btn-check" name="btnradio[]" id="btnradio4" autocomplete="off" value="3" v-model="filter.bedroom">
                                    <label class="btn btn-white" for="btnradio4" style="border-radius: 0; padding: 5px 15px; flex: none;">3</label>

                                    <input type="checkbox" class="btn-check" name="btnradio[]" id="btnradio5" autocomplete="off" value="4" v-model="filter.bedroom">
                                    <label class="btn btn-white" for="btnradio5" style="padding: 5px 15px; flex: none;">4+</label>
                                </div>
                                </div>
                            </div>

                            <div class="col-4 py-1">
                                <label class="mb-2 text-center w-100">Техническое оснащение</label>
                                <div class="row">
                                    <div class="col-6">

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" value="pool" id="service_9" v-model="filter.service">
                                        <label class="form-check-label" for="service_9">
                                            Бассейн
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" value="gym" id="service_1" v-model="filter.service">
                                        <label class="form-check-label" for="service_1">
                                            Тренажерный зал
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" value="cond" id="service_15" v-model="filter.service">
                                        <label class="form-check-label" for="service_15">
                                            Кондиционер
                                        </label>
                                    </div>

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" value="tv" id="service_5" v-model="filter.service">
                                        <label class="form-check-label" for="service_5">
                                            Телевизор
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" value="wm" id="service_4" v-model="filter.service">
                                        <label class="form-check-label" for="service_4">
                                            Стирал. машина
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" value="dw" id="service_2" v-model="filter.service">
                                        <label class="form-check-label" for="service_2">
                                            Посудом. машина
                                        </label>
                                    </div>

                                    </div>

                                    <div class="col-6">
                                    
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" value="micro" id="service_8" v-model="filter.service">
                                        <label class="form-check-label" for="service_8">
                                            Микроволновка
                                        </label>
                                    </div>

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" value="coffee" id="service_12" v-model="filter.service">
                                        <label class="form-check-label" for="service_12">
                                            Кофемашина
                                        </label>
                                    </div>

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" value="plate" id="service_7" v-model="filter.service">
                                        <label class="form-check-label" for="service_7">
                                            Плита
                                        </label>
                                    </div>

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" value="oven" id="service_16" v-model="filter.service">
                                        <label class="form-check-label" for="service_16">
                                            Духовка
                                        </label>
                                    </div>

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" value="hood" id="service_17" v-model="filter.service">
                                        <label class="form-check-label" for="service_17">
                                            Вытяжка
                                        </label>
                                    </div>

                                    </div>
                                </div>
                            </div>

                            <div class="col-4 py-1 px-5">
                                <label class="mb-2">Расстояние до пляжа:</label>
                                <select class="form-control form-select py-0" style="height: 30px;" v-model="filter.beach">
                                    <option value="">Не важно</option>
                                    <option value="500">До 500 м</option>
                                    <option value="1000">До 1000 м</option>
                                    <option value="2000">До 2000 м</option>
                                </select>

                                <div  v-if="$props.type !== 'Покупка'">
                                    <label class="form-check-label mt-3">
                                        Домашние питомцы
                                    </label>

                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="1" id="special_1" v-model="filter.animals">
                                        <label class="form-check-label" for="special_1">
                                            Разрешается
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="2" id="special_2" v-model="filter.animals">
                                        <label class="form-check-label" for="special_2">
                                            Обсуждается
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="0" id="special_3" v-model="filter.animals">
                                        <label class="form-check-label" for="special_3">
                                            Запрещается
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <a href="#" class="btn-white ms-auto me-3 mb-3" style="margin-top: -30px;" @click="applyFilter(0)">Применить</a>
                        </div>
                        
                        
                        

                        <!--<div class="ms-3">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="bedroom_check_1">
                                <label class="form-check-label" for="bedroom_check_1">
                                    1
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="bedroom_check_2">
                                <label class="form-check-label" for="bedroom_check_2">
                                    2
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="bedroom_check_3">
                                <label class="form-check-label" for="bedroom_check_3">
                                    3
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="bedroom_check_4">
                                <label class="form-check-label" for="bedroom_check_4">
                                    4
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="bedroom_check_5">
                                <label class="form-check-label" for="bedroom_check_5">
                                    5
                                </label>
                            </div>
                        </div>-->
                        
                        

                        <!--<p class="mt-4 mb-2">Особенности</p>-->
                        

                        
                    </div>
                    
                    </div>

                <div class="col-12">
                    <div class="catalog mt-lg-0 mt-5">
                        <div class="row mb-5 d-flex">
                            <div class="col-lg-3 col-12 mb-3" v-for="item in items" :key="item.id" :id="'item_card_'+item.id">
                                <a :href="'/items/'+item.id" class="catalog-item">
            <div :id="'carouselExampleIndicators'+item.id" class="carousel slide" data-bs-touch="true" style="position: relative;">
              <div class="carousel-indicators">
                  <button type="button" v-for="(img, index) in JSON.parse(item.images)" :key = "index" :class='{active: index == 0}' :data-bs-target="'#carouselExampleIndicators'+item.id" :data-bs-slide-to="index" aria-current="true"></button>
              </div>

              <div class="carousel-inner">
                <div class="carousel-item item-preview" v-for="(img, index) in JSON.parse(item.images)" :key = "index" :class='{active: index == 0}'>
                  <img v-lazy="'https://samui31.com/api/images_mini/'+img" class="d-block w-100" alt="...">
                </div>
              </div>
              <button class="carousel-control-prev" type="button" :data-bs-target="'#carouselExampleIndicators'+item.id" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" :data-bs-target="'#carouselExampleIndicators'+item.id" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>

              <div style="position: absolute; top: 10px; left: 12px; z-index: 10; color: #fff; opacity: 0.7; text-shadow:  1px 0 1px #000, 0 1px 1px #000, -1px 0 1px #000, 0 -1px 1px #000; font-size: 0.7em;">{{ item.number }}</div>

              <a v-if="$store.state.user == false" class="favourite-button" style="position: absolute; bottom: 10px; right: 15px; z-index: 10;" onclick="alert('Доступно только авторизированным пользователям'); return false"><i class="bi bi-heart-fill" style="opacity: 0.8; color: #fff; font-size: 1.2em;"></i></a>
                <div v-if="$store.state.user == true">
                  <a class="favourite-button" style="position: absolute; bottom: 10px; right: 15px; z-index: 10;" v-if="$store.state.user_info.favourites.includes(item.id)" @click.prevent="removeFromFavourites(item.id)"><i class="bi bi-heart-fill" style="color: #ff033e; font-size: 1.2em;"></i></a>
                  <a class="favourite-button" style="position: absolute; bottom: 10px; right: 15px; z-index: 10;" v-else @click.prevent="addToFavourites(item.id)"><i class="bi bi-heart-fill" style="opacity: 0.8; color: #fff; font-size: 1.2em;"></i></a>
                </div>

           </div>

            <div class="catalog-item-description">
            
            <span class="catalog-params">
              <div>
                <i class="bi bi-moon-stars-fill"></i> {{ item.bedrooms }} спал. &nbsp; <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" role="presentation" focusable="false" style="width: 1.2em; fill: currentcolor;"><path d="M7 1a3 3 0 0 0-3 2.82V31h2V4a1 1 0 0 1 .88-1H18a1 1 0 0 1 1 .88V5h-5a1 1 0 0 0-1 .88V9h-3v2h19V9h-2V6a1 1 0 0 0-.88-1H21V4a3 3 0 0 0-2.82-3H7zm13 28a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zM15 7h10v2H15V7z"></path></svg> {{ item.bathrooms }} сануз. &nbsp; <i class="bi bi-tsunami"></i> {{ item.beach }}м
              </div>
            </span>
            <p style="min-height: 2lh; font-weight: 700;" class="mb-0">{{ item.title }}</p>
            <p class="mb-1 mt-2">Район: {{ item.place }}</p>
            <span class="catalog-price" v-if="item.type == 'Аренда'">от {{ Number(item.price).toLocaleString('ru-RU') }} Thb в месяц</span>
            <span class="catalog-price" v-if="item.type == 'Покупка'">{{ Number(item.price).toLocaleString('ru-RU') }} Thb</span>
            <p class="text-success d-flex" v-if="item.status == 'Свободен' && item.type == 'Аренда'"><img src="../assets/calendar.png" class="item-status-icon" alt=""> <span class="my-auto ms-1">{{ item.status }} {{ item.status_text }}</span></p>
            <p class="text-danger d-flex" v-if="item.status == 'Занят' && item.type == 'Аренда'"><img src="../assets/calendar.png" class="item-status-icon" alt=""> <span class="my-auto ms-1">{{ item.status }} {{ item.status_text }}</span></p>
            <p class="text-primary d-flex" v-if="item.status == 'Под запрос' && item.type == 'Аренда'"><img src="../assets/calendar.png" class="item-status-icon" alt=""> <span class="my-auto ms-1">{{ item.status }} {{ item.status_text }}</span></p>

            <!--<div class="d-flex justify-content-end">
              <a href="" v-if="$store.state.user == false" class="my-auto me-3"><span class="catalog-params" onclick="alert('Доступно только авторизированным пользователям'); return false">В избранное</span></a>
                <a v-if="$store.state.user == true" class="my-auto me-3">
                  <span class="catalog-params red_text" v-if="$store.state.user_info.favourites.includes(item.id)" @click.prevent="removeFromFavourites(item.id)">Удалить</span>
                  <span class="catalog-params" v-else  @click.prevent="addToFavourites(item.id)">В избранное</span>
              </a>
              <a href="" @click.prevent="offer.item = item.title;  offer.type = item.type" class="btn btn-sm btn-outline-success" data-bs-toggle="modal" data-bs-target="#offerModal">Оставить заявку</a>
            </div>-->
            
            </div>
          </a>
                            </div>

                        </div>
                        
                    </div>

                    <div class="order-pagination" style="margin-top: -30px;">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination pb-3 justify-content-center">
                        <li class="page-item" style="cursor: pointer" :class="{active: pageNum == page+1}" :key="pageNum" v-for="pageNum in pages_count" @click="applyFilter(pageNum-1)">
                            <span class="pagination-button">{{ pageNum }}</span>
                        </li>
                        </ul>
                    </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<style lang="scss">
@import url("@vueform/slider/themes/default.css");
</style>

<script>
import Slider from '@vueform/slider'
import axios from 'axios'

  export default {
    props: {
        type: String
    },
    components: {
        Slider
    },
    data(){
        return{
            priceSlider: {
                value: [0, 0],
                max: 1000000,
                min: 60000,
                tooltipPosition: 'bottom',
                step: 500,
                format: {
                    prefix: '฿',
                    thousand: ' ',
                    encoder: v=> Math.round(v)
                }
            },
            filter: {
                place: [""],
                service: [],
                animals: [],
                beach: '',
                bedroom: [
                ]
            },
            items: [],
            offer: {
                item: '',
                type: this.$props.type
            },
            page: 0,
            pages_count: 0,
            windowWidth: 0
        }
    },
    methods: {
        loadData(get_param){
            if(get_param){
                axios.get('https://samui31.com/api/get_items.php?' + get_param).then((response)=>{
                    console.log(response.data)
                    this.items = response.data[0]
                    this.pages_count = response.data[1]
                })
            }
            else{
                if(window.location.search.length == 0) window.location.search = '?type=' + this.$props.type + '&page=0'
                axios.get('https://samui31.com/api/get_items.php' + window.location.search).then((response)=>{
                    console.log(response.data)
                    this.items = response.data[0]
                    this.pages_count = response.data[1]
                }) 
            }
            if(this.$store.state.user == true){
                this.offer.name = this.$store.state.user_info.name
                this.offer.phone = this.$store.state.user_info.phone
                this.offer.mail = this.$store.state.user_info.mail
            }
        },
        getFilterData(){
            axios.post('https://samui31.com/api/get_filter_data.php', {type: this.$props.type}).then((response)=>{
                this.priceSlider.value = response.data
                this.priceSlider.min = Number(response.data[0])
                this.priceSlider.max = Number(response.data[1])
                console.log(response.data)
            })
        },
        loadFavourites(){
            if(this.$store.state.user == true){
                axios.post('https://samui31.com/api/favourites.php', {action: 'get_id', user_id: this.$store.state.user_info.id}).then((response)=>{
                    this.$store.commit('authFavourites', response.data)
                    console.log(this.$store.state.user_info.favourites)
                })
            }
        },
        getParam(){
            let get_param = ''
            get_param+="page=" + this.page
            for(let i=0; i<this.filter.place.length; i++){
                if(this.filter.place[i] !== '') get_param += '&place[]=' + this.filter.place[i]
                
            }
            for(let i=0; i<this.filter.service.length; i++){
                get_param += '&'
                get_param += this.filter.service[i] + '=true'
            }
            for(let i=0; i<this.filter.animals.length; i++){
                get_param += '&animals[]=' + this.filter.animals[i]
            }
            get_param += '&'
            get_param += 'minprice=' + this.priceSlider.value[0]
            get_param += '&'
            get_param += 'maxprice=' + this.priceSlider.value[1]
            if(this.filter.beach !== '') get_param += '&beach=' + this.filter.beach
            for(let i=0; i<this.filter.bedroom.length; i++){
                get_param += '&bedroom[]=' + this.filter.bedroom[i]
            }
            get_param = get_param + '&type=' + this.$props.type

            return get_param
        },
        applyFilter(page){
            this.page = page
            console.log(this.getParam())
            this.loadData(this.getParam())
        },
        addToFavourites(id){
            axios.post('https://samui31.com/api/favourites.php', {action: 'add', user_id: this.$store.state.user_info.id, item_id: id}).then(()=>{
                this.loadFavourites()
            })
        },
        removeFromFavourites(id){
            axios.post('https://samui31.com/api/favourites.php', {action: 'delete', user_id: this.$store.state.user_info.id, item_id: id}).then(()=>{
                this.loadFavourites()
            })
        },
        sendOffer(){
            axios.post('https://samui31.com/api/sendOffer.php', this.offer).then(()=>{
                alert('Заявка отправлена')

                window.location.href = window.location.pathname + '?' + this.getParam()
            })
        }
    },
    created(){
        this.getFilterData()
        this.loadData()
        this.windowWidth = window.innerWidth
    }
  }
</script>