<template>

  <div class="modal fade px-0" :id="'openBidImg'+id" tabindex="-1" aria-labelledby="openBidImgLabel" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen modal-dialog-centered" style="height: fit-content;">
      <div class="modal-content" style="background: none;">
        <div :id="'itemSliderModalBid'+id" class="carousel slide item-slider" data-bs-touch="true" data-bs-interval="false">

          <div class="carousel-inner">
            <div class="carousel-item" :class="{'active': index === openImgSrc}" v-for="(img, index) in JSON.parse(itemInfo.images)" :key="index">
              <img v-lazy="'https://samui31.com/api/images/'+img" class="d-block w-100" style="object-fit: contain; height: 100vh; max-width: 100vw; cursor: pointer;" data-bs-dismiss="modal">
            </div>
          </div>
          <button class="carousel-control-prev" type="button" :data-bs-target="'#itemSliderModalBid'+id"  data-bs-slide="prev" >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Предыдущий</span>
          </button>
          <button class="carousel-control-next" type="button" :data-bs-target="'#itemSliderModalBid'+id" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Следующий</span>
          </button>
          </div>

      </div>
    </div>
  </div>
    <div>
    <div class="item-container container">
        
        <div class="d-lg-flex justify-content-between pt-4 mb-2">
            <h1 class="catalog-page-title" style="font-weight: 600; ">{{ itemInfo.mark }} {{ itemInfo.model }} ({{ itemInfo.year }})</h1>
            <div class="mt-auto">
                <a v-if="$store.state.user == false" class="d-flex"><span class="d-block me-1 my-auto" style="opacity: 0.7;" onclick="alert('Доступно только авторизированным пользователям')">Добавить в избранное</span> <i class="bi bi-heart" style="opacity: 0.7; color: #000; font-size: 1.2em;"></i></a>
                <a v-if="$store.state.user == true" style="opacity: 0.7;">
                    <a v-if="$store.state.user_info.favouritesMoto.includes(itemInfo.id)" @click="removeFromFavourites(itemInfo.id)" style="color: #ff033e; display: flex;"><span class="d-block me-1 my-auto">Убрать из избранного</span> <i class="bi bi-heart-fill" style="color: #ff033e; font-size: 1.2em;"></i></a>
                    <a v-else  @click="addToFavourites(itemInfo.id)" class="d-flex"><span class="d-block me-1 my-auto">Добавить в избранное</span> <i class="bi bi-heart" style="opacity: 0.7; color: #000; font-size: 1.2em;"></i></a>
                </a>
            </div>
        </div>

        <div class="item-images">
            <div class="row">
                <div class="col-6 px-1">
                    <img v-lazy="'https://samui31.com/api/images/'+JSON.parse(itemInfo.images)[0]" class="d-block w-100" @click="openImgGetSrc(0)" data-bs-toggle="modal" :data-bs-target="'#openBidImg'+id" style="cursor: pointer; border-top-left-radius: 15px; border-bottom-left-radius: 15px;">
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col-6 px-1 py-1 pt-0">
                            <img v-if="JSON.parse(itemInfo.images)[1]" v-lazy="'https://samui31.com/api/images/'+JSON.parse(itemInfo.images)[1]" class="d-block w-100" @click="openImgGetSrc(1)"  data-bs-toggle="modal" :data-bs-target="'#openBidImg'+id" style="cursor: pointer;">
                        </div>
                        <div class="col-6 px-1 py-1 pt-0">
                            <img v-if="JSON.parse(itemInfo.images)[2]" v-lazy="'https://samui31.com/api/images/'+JSON.parse(itemInfo.images)[2]" class="d-block w-100" @click="openImgGetSrc(2)"  data-bs-toggle="modal" :data-bs-target="'#openBidImg'+id" style="cursor: pointer; border-top-right-radius: 15px;">
                        </div>
                        <div class="col-6 px-1 py-1">
                            <img v-if="JSON.parse(itemInfo.images)[3]" v-lazy="'https://samui31.com/api/images/'+JSON.parse(itemInfo.images)[3]" class="d-block w-100" @click="openImgGetSrc(3)"  data-bs-toggle="modal" :data-bs-target="'#openBidImg'+id" style="cursor: pointer;">
                        </div>
                        <div class="col-6 px-1 py-1">
                            <img v-if="JSON.parse(itemInfo.images)[4]" v-lazy="'https://samui31.com/api/images/'+JSON.parse(itemInfo.images)[4]" class="d-block w-100" @click="openImgGetSrc(4)"  data-bs-toggle="modal" :data-bs-target="'#openBidImg'+id" style="cursor: pointer; border-bottom-right-radius: 15px;">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex mt-2">
            <a data-bs-toggle="modal" :data-bs-target="'#openBidImg'+id" class="btn-white btn-white-sm ms-auto" style="padding-top: 3px !important; padding-bottom: 3px !important;" @click="openImgGetSrc(0)">Посмотреть все фото</a>
        </div>
    </div>

    <div class="item-description">
    <div class="container">
        <div class="row text-center text-lg-start">

            <div class="col-lg-6 col-12">
                <hr class="mt-0">
                <h1 class="catalog-page-title" style="font-weight: 700; font-size: 22px;">Характеристики</h1>
                <ul class="mt-0">
                    <li class="d-flex mb-2">
                        <b>Марка: {{ itemInfo.mark }}</b>
                    </li>
                    <li class="d-flex mb-2">
                        <b>Модель: {{ itemInfo.model }}</b>
                    </li>
                    <li class="d-flex mb-2">
                        <b>Объем двигателя: {{ itemInfo.engine }}&nbsp;</b><b v-if="itemInfo.type == 'Авто'">л.</b> <b v-else>куб.</b>
                    </li>
                    <li class="d-flex mb-2">
                        <b>Год выпуска: {{ itemInfo.year }} г.</b>
                    </li>
                    <li class="d-flex mb-2">
                        <b>Пробег: {{ Number(itemInfo.probeg).toLocaleString('ru-RU') }} км.</b>
                    </li>
                </ul>
                <div v-if="itemInfo.description !== ''">
                   <hr>
                    <h1 class="catalog-page-title" style="font-weight: 700; font-size: 22px;">Описание</h1>
                    <p class="mb-3 mt-2 text-start" style="white-space: pre-wrap;">{{ itemInfo.description }}</p>
                     
                </div>
                <hr>
                <h1 class="catalog-page-title mt-0" style="font-weight: 700;  font-size: 22px; ">Ценовое предложение:</h1>
                <ul>
                    <li class="mb-2 mt-1 d-flex" v-if="Number(itemInfo.priceForDay) !== 0"><b>Стоимость за сутки: {{ Number(itemInfo.priceForDay).toLocaleString('ru-RU')  }} бат</b></li>
                    <li class="mb-2 mt-1 d-flex" v-if="Number(itemInfo.priceForWeek) !== 0"><b>Стоимость за неделю: {{ Number(itemInfo.priceForWeek).toLocaleString('ru-RU')  }} бат</b></li>
                    <li class="mb-2 mt-1 d-flex" v-if="Number(itemInfo.priceForMonth) !== 0"><b>Стоимость за месяц: {{ Number(itemInfo.priceForMonth).toLocaleString('ru-RU')  }} бат</b></li>
                    <li class="mb-2 mt-1 d-flex" v-if="Number(itemInfo.deposit) !== 0"><b>Депозит: {{ Number(itemInfo.deposit).toLocaleString('ru-RU')  }} бат</b></li>
                </ul>

                <hr>
                <h1 class="catalog-page-title mt-0" style="font-weight: 700;  font-size: 22px;">Контакты для бронирования:</h1>
                <li class="mb-2 d-flex" v-if="itemInfo.contacts && itemInfo.contacts !== ''"><img src="../assets/tg_icon.png" class="item-services-icon" alt="">
                    <a v-if="itemInfo.contacts.slice(0,1) == '@'" :href="'https://t.me/'+itemInfo.contacts.slice(1)" target="_blank"><b>{{itemInfo.contacts}}</b></a>
                    <a v-if="itemInfo.contacts.slice(0,1) == '+'" :href="'tel:+'+itemInfo.contacts.replace('/[^0-9]/g', '')" target="_blank"><b>{{itemInfo.contacts}}</b></a>
                    <b v-if="itemInfo.contacts.slice(0,1) !== '@' && itemInfo.contacts.slice(0,1) !== '+'">{{itemInfo.contacts}}</b>
                </li>
                <li class="mb-2 d-flex">
                    <img src="../assets/tg_icon.png" class="item-services-icon" alt="">
                    <a href="https://t.me/samui_31" target="_blank"><b>@samui_31</b></a>
                </li>
            </div>

            <div class="col-lg-6 col-12">
                <img :src="'https://samui31.comhttps://samui31.com/api/images/' + itemInfo['map']" class="w-100" alt="">
            </div>

            <div class="item-price-block my-5">
                    
                </div>
            

            <!--<div class="col-12">
                <h2 class="catalog-page-title">Смотрите также</h2>

                <div class="catalog mt-5">
                        <div class="row mb-5">
                            <div class="col-lg-4 col-12 mb-5" v-for="item in itemInfo.analog_items" :key="item.id">
                            <a :href="'/items/'+item.id" class="catalog-item">

                                <div :id="'carouselExampleIndicators'+item.id" class="carousel slide">
                                <div class="carousel-inner">
                                    <div class="carousel-item" v-for="(img, index) in JSON.parse(item.images)" :key = "index" :class='{active: index == 0}'>
                                    <img v-lazy="'https://samui31.com/api/images_mini/'+img" class="d-block w-100" alt="...">
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" :data-bs-target="'#carouselExampleIndicators'+item.id" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" :data-bs-target="'#carouselExampleIndicators'+item.id" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                                </div>

                                <div class="catalog-item-description">
                                <span class="catalog-price" v-if="$props.type == 'Аренда'"><b>{{ Number(item.price).toLocaleString('ru-RU') }} ฿</b>/мес.</span>
                                <span class="catalog-price" v-if="$props.type == 'Покупка'"><b>{{ Number(item.price).toLocaleString('ru-RU') }} ฿</b></span>
                                <span class="catalog-params"><i class="bi bi-moon-stars-fill"></i> {{ item.bedrooms }} спал. &emsp; <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" role="presentation" focusable="false" style="width: 1.2em; fill: currentcolor;"><path d="M7 1a3 3 0 0 0-3 2.82V31h2V4a1 1 0 0 1 .88-1H18a1 1 0 0 1 1 .88V5h-5a1 1 0 0 0-1 .88V9h-3v2h19V9h-2V6a1 1 0 0 0-.88-1H21V4a3 3 0 0 0-2.82-3H7zm13 28a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zM15 7h10v2H15V7z"></path></svg> {{ item.bathrooms }} сануз. &emsp; <i class="bi bi-tsunami"></i> {{ item.beach }}м</span>
                                <p>{{ item.title }}</p>
                                <div class="d-flex justify-content-end">
                                    <a href="#" v-if="$store.state.user == false" class="my-auto me-3"><span class="catalog-params" onclick="alert('Доступно только авторизированным пользователям')">В избранное</span></a>
                                    <a v-if="$store.state.user == true" class="my-auto me-3">
                                        <a href="#" class="catalog-params red_text" v-if="$store.state.user_info.favourites.includes(item.id)" @click="removeFromFavourites(item.id)">Удалить</a>
                                        <a href="#" class="catalog-params" v-else  @click="addToFavourites(item.id)">В избранное</a>
                                        
                                    </a>
                                    <a href="#" @click="offer.item = item.title" class="btn btn-sm btn-outline-success" data-bs-toggle="modal" data-bs-target="#offerModal">Оставить заявку</a>
                                </div>
                                </div>
                            </a>
                            </div>

                        </div>
                        
                    </div>
            </div>-->
        </div>
        
    </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'

export default{
    props: {
        id: Number
    },

    data(){
        return{
            itemInfo: [],
            offer: {
                item: ''
            },
            openImgSrc: 0
        }
    },
    methods: {
        loadData(){
            axios.get('https://samui31.com/api/get_item_moto.php?id=' + this.$props.id).then((response)=>{
                this.itemInfo = response.data
                console.log(this.itemInfo)
            })
        },
        addToFavourites(id){
            axios.post('https://samui31.com/api/favourites.php', {action: 'add', user_id: this.$store.state.user_info.id, item_id: id, type: 'moto'}).then(()=>{
                if(this.$store.state.user == true){
                axios.post('https://samui31.com/api/favourites.php', {action: 'get_id', user_id: this.$store.state.user_info.id, type: 'moto'}).then((response)=>{
                    this.$store.commit('authFavouritesMoto', response.data)
                    console.log(this.$store.state.user_info.favouritesMoto)
                })
                }
            })
        },
        removeFromFavourites(id){
            axios.post('https://samui31.com/api/favourites.php', {action: 'delete', user_id: this.$store.state.user_info.id, item_id: id, type: 'moto'}).then(()=>{
                if(this.$store.state.user == true){
                axios.post('https://samui31.com/api/favourites.php', {action: 'get_id', user_id: this.$store.state.user_info.id, type: 'moto'}).then((response)=>{
                    this.$store.commit('authFavouritesMoto', response.data)
                    console.log(this.$store.state.user_info.favouritesMoto)
                })
                }
            })
        },
        sendOffer(){
            this.offer.type = this.itemInfo.type
            axios.post('https://samui31.com/api/sendOffer.php', this.offer).then(()=>{
                alert('Заявка отправлена')
                window.location.reload()
            })
        },
        openImgGetSrc(index){
            document.querySelector('#itemSliderModalBid'+this.$props.id).querySelector('.active').classList.remove('active')
            document.querySelector('#itemSliderModalBid'+this.$props.id).querySelectorAll('.carousel-item')[index].classList.add('active')
            this.openImgSrc = index
            console.log(this.openImgSrc)
        }
    },
    created(){
        this.loadData()
    }
}
</script>