import { createRouter, createWebHistory } from 'vue-router'
import MainPage from '@/views/MainPage.vue'
import ItemsPage from '@/views/ItemsPage.vue'
import ItemPage from '@/views/ItemPage.vue'
import AdminPanelPage from '@/views/AdminPanelPage.vue'
import AuthPage from '@/views/AuthPage.vue'
import RegPage from '@/views/RegPage.vue'
import ForgotPassword from '@/views/ForgotPassword.vue'
import LkPage from '@/views/LkPage.vue'
import AddReview from '@/views/AddReview.vue'
import MotoPage from '@/views/MotoPage.vue'
import ItemMotoPage from '@/views/ItemMotoPage.vue'
import NewsPage from '@/views/NewsPage.vue'
import AgentsPanelPage from '@/views/AgentPanelPage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: MainPage
  },
  {
    path: '/rent',
    name: 'rent',
    component: ItemsPage,
    props: {
      type: 'Аренда'
    }
  },
  {
    path: '/buy',
    name: 'buy',
    component: ItemsPage,
    props: {
      type: 'Покупка'
    }
  },
  {
    path: '/items/:id',
    name: 'item',
    component: ItemPage,
    props: true
  },
  {
    path: '/admin/:type',
    name: 'admin',
    component: AdminPanelPage,
    props: true
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthPage
  },
  {
    path: '/reg',
    name: 'reg',
    component: RegPage
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword
  },
  {
    path: '/lk',
    name: 'lk',
    component: LkPage
  },
  {
    path: '/add_review',
    name: 'add_review',
    component: AddReview
  },
  {
    path: '/moto',
    name: 'moto',
    component: MotoPage
  },
  {
    path: '/moto/:id',
    name: 'moto_item',
    component: ItemMotoPage,
    props: true
  },
  {
    path: '/news',
    name: 'news',
    component: NewsPage
  },
  {
    path: '/agent/:type',
    name: 'agent',
    component: AgentsPanelPage,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
